<template>
  <div>
    <div class="header-secondary title-tabs header-title d-none d-sm-block">
      Status Setting
    </div>
    <div class="pt-2 container">
      <div><label class="label">options</label></div>
      <div class="row flex-column justify-content-center">
        <div
          v-for="(i, index) of form.options"
          :key="index"
          class="col-12 col-sm-8 col-md-8 col-lg-6 row align-items-center pr-0 mb-2"
        >
          <div class="col-12 col-md-3 col-sm-4 d-flex justify-content-between">
            <div>
              <font-awesome-icon
                v-if="i.fill || !DisabledFixID.includes(i.booking_status_id)"
                icon="chevron-circle-up"
                :class="[index == 1 && 'disabled', 'f-20']"
                @click="handleMoveElement(i, index, -1)"
              />
            </div>
            <div># {{ index + 1 }}</div>
            <div>
              <font-awesome-icon
                v-if="i.fill || !DisabledFixID.includes(i.booking_status_id)"
                @click="handleMoveElement(i, index, +1)"
                :class="[
                  form.options.length - 3 == index && 'disabled',
                  'f-20',
                ]"
                icon="chevron-circle-down"
              />
            </div>
          </div>
          <div
            class="px-3 cursor-pointer col-12 col-sm-8"
            v-if="i.fill != 'checked' && i.fill"
            @click="handleAddOptions(i, index)"
          >
            <u>Add Options</u>
          </div>
          <div v-else class="col-12 col-sm-8">
            <InputText
              class="mb-0"
              textFloat=""
              placeholder=""
              type="text"
              name=""
              required
              :disabled="!i.fill && DisabledFixID.includes(i.booking_status_id)"
              v-model="i.name"
            />
            <font-awesome-icon
              v-if="!DisabledFixID.includes(i.booking_status_id)"
              class="f-20 delete-icon"
              @click="handleDeleteOptions(i, index)"
              icon="trash-alt"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: { required: true },
  },
  data() {
    return {
      DisabledFixID: [1, 2, 3],
      // form.options: [],
    };
  },
  mounted() {
    let id = this.$route.params.id;
    if (id != 0) {
      let length = this.form.options.length - 2;
      let obj = { booking_id: id, booking_status_id: 0, name: "", fill: true };
      this.form.options.splice(length, 0, obj);
    }
  },

  methods: {
    sortArray() {
      this.form.options.map((el, i) => (el.sort_order = i + 1));
    },
    handleMoveElement(items, index, val) {
      let i = index + val;
      let dataLength = this.form.options.length;
      if (i == 0) {
        return;
      }
      if (i == dataLength - 2) return;
      this.form.options.splice(index, 1);
      this.form.options.splice(index + val, 0, items);
      this.sortArray();
    },
    handleAddOptions(items, index) {
      let obj = {
        booking_id: this.$route.params.id,
        booking_status_id: 0,
        name: "",
        fill: true,
      };
      this.form.options.splice(index + 1, 0, obj);
      items.fill = "checked";
      this.sortArray();
    },
    handleDeleteOptions(items, index) {
      // let obj = { booking_status: 0, name: "", fill: true };
      this.form.options.splice(index, 1);
      items.fill = "checked";
      this.sortArray();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .delete-icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: -10px;
}
svg {
  cursor: pointer;
  &.disabled {
    color: whitesmoke;
    // padding: 20px;
    cursor: default;
    //
  }
}
</style>
