var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-select',{staticClass:"mt-3 mb-2 w-25",attrs:{"options":_vm.typeOption,"value-field":"value","text-field":"name"},on:{"change":function($event){_vm.numberType = 1}},model:{value:(_vm.questionList[_vm.questionActive].field_conditions[0].value),callback:function ($$v) {_vm.$set(_vm.questionList[_vm.questionActive].field_conditions[0], "value", $$v)},expression:"questionList[questionActive].field_conditions[0].value"}})],1),(
        _vm.questionList[_vm.questionActive].field_conditions[0].value == 'number'
      )?_c('b-col',{staticClass:"mt-4 pr-1",attrs:{"sm":"6"}},[_c('b-form-select',{attrs:{"options":_vm.numberOption,"value-field":"value","text-field":"name"},on:{"change":_vm.changeNumberType},model:{value:(_vm.numberType),callback:function ($$v) {_vm.numberType=$$v},expression:"numberType"}})],1):_vm._e(),(
        _vm.questionList[_vm.questionActive].field_conditions[0].value == 'text' ||
        _vm.numberType == 1
      )?_c('b-col',{class:_vm.questionList[_vm.questionActive].field_conditions[0].value == 'text'
          ? ''
          : 'pl-1',attrs:{"sm":_vm.questionList[_vm.questionActive].field_conditions[0].value == 'text'
          ? '12'
          : '6'}},[_c('InputText',{staticClass:"text-float-normal",attrs:{"textFloat":"Length","placeholder":"Length","type":"number"},model:{value:(_vm.questionList[_vm.questionActive].field_conditions[1].value),callback:function ($$v) {_vm.$set(_vm.questionList[_vm.questionActive].field_conditions[1], "value", $$v)},expression:"questionList[questionActive].field_conditions[1].value"}})],1):_vm._e(),(_vm.numberType == 2)?_c('b-col',{staticClass:"pl-1",attrs:{"sm":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('InputText',{staticClass:"mr-1 text-float-normal",attrs:{"textFloat":"Min","placeholder":"Min","type":"number"},model:{value:(_vm.questionList[_vm.questionActive].field_conditions[2].value),callback:function ($$v) {_vm.$set(_vm.questionList[_vm.questionActive].field_conditions[2], "value", $$v)},expression:"questionList[questionActive].field_conditions[2].value"}}),_c('InputText',{staticClass:"ml-1 text-float-normal",attrs:{"textFloat":"Max","placeholder":"Max","type":"number"},model:{value:(_vm.questionList[_vm.questionActive].field_conditions[3].value),callback:function ($$v) {_vm.$set(_vm.questionList[_vm.questionActive].field_conditions[3], "value", $$v)},expression:"questionList[questionActive].field_conditions[3].value"}})],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }